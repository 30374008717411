import React, { useState, useEffect, useRef } from 'react';

interface MultiSelectDropdownProps {
  options: string[];
  selectedOptions: string[];
  setSelectedOptions: (selected: string[]) => void;
}

const MultiSelectDropdownv2: React.FC<MultiSelectDropdownProps> = ({
  options,
  selectedOptions,
  setSelectedOptions,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const handleCheckboxChange = (option: string) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div ref={dropdownRef} style={{ position: 'relative' }}>
      <div onClick={toggleDropdown} style={{ border: '1px solid #ccc', height: 26, padding: '0px', cursor: 'pointer' }}>
        {selectedOptions.length > 0 ? selectedOptions.join(', ') : 'Select options'}
      </div>
      {isOpen && (
        <div
          style={{
            position: 'absolute',
            zIndex: 1000,
            border: '1px solid #ccc',
            marginTop: '8px',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            backdropFilter: 'blur(5px)',
            width: '100%',
          }}
        >
          {options.map((option) => (
            <label key={option} style={{ display: 'block', padding: '4px 8px' }}>
              <input
                type="checkbox"
                checked={selectedOptions.includes(option)}
                onChange={() => handleCheckboxChange(option)}
              />
              {  } {option}
            </label>
          ))}
        </div>
      )}
    </div>
  );
};

export default MultiSelectDropdownv2;