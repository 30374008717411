/* eslint-disable react-hooks/exhaustive-deps */
import * as C from "./styles";
import { Title } from "../../components/Title/Title";
import { Button } from "../../components/Button/Button";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import "devextreme/dist/css/dx.light.css";
import { NumberBox, SelectBox, TextBox } from "devextreme-react";
import {
  CategorieSubscribe,
  YesNot,
} from "../../data/data";
import { useCallback, useEffect, useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../store";
import hibridoData from "../../data/hibridos.json"
import NumericTextBox from "../../components/NumericTextBox";
import AutoCompleteCustom, { IHibrido } from "../../components/AutoCompleteCustom";

export const FormStep8 = () => {
  const subscription = useAppStore((store) => store.subscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const setSubscription = useAppStore((store) => store.setSubscription);
  const setStep = useAppStore((store) => store.setStep);

  const navigate = useNavigate();
  const [mesmo_talhao, setMesmoTalhao] = useState(subscription.history?.mesmo_talhao);
  const [mesmo_talhao_temp, setMesmoTalhaoTemp] = useState('');

  const [category, setCategory] = useState(subscription.history?.category);
  const [nome_talha, setNomeTalha] = useState(subscription.history?.nome_talha);
  const [hibrido, setHibrido] = useState(subscription.history?.hibrido);
  const [expec_produtividade, setExpecProdutividade] = useState(
    subscription.history?.expec_produtividade
  );
  const [densidade_media, setDensidadeMedia] = useState(
    subscription.history?.densidade_media
  );
  const [usa_fadigital, setUsaFadigital] = useState(
    subscription.history?.usa_fadigital
  );
  const [ferramenta_fadigital, setFerramentaFadigital] = useState(
    subscription.history?.ferramenta_fadigital
  );
  const [nivel_fadigital, setNivelFadigital] = useState(
    subscription.history?.nivel_fadigital
  );
  const [satisfacao_fadigital, setSatisfacaoFadigital] = useState(
    subscription.history?.satisfacao_fadigital
  );
  const [repetir_fadigital, setRepetirFadigital] = useState(
    subscription.history?.repetir_fadigital
  );

  const [custo_producao, setCustoProducao] = useState(
    subscription.history?.custo_producao
  );
  const [altitude, setAltitude] = useState(
   subscription.history?.altitude
  );
  const [latitude, setLatitude] = useState(
    subscription.history?.latitude
  );
  const [longitude, setLongitude] = useState(
    subscription.history?.longitude
  );

  const [ano_1, setAno1] = useState(subscription.history?.ano_1);
  const [safra_1, setSafra1] = useState(subscription.history?.safra_1);
  const [cultura_1, setCultura1] = useState(subscription.history?.cultura_1);
  const [ano_2, setAno2] = useState(subscription.history?.ano_2);
  const [safra_2, setSafra2] = useState(subscription.history?.safra_2);
  const [cultura_2, setCultura2] = useState(subscription.history?.cultura_2);
  const [ano_3, setAno3] = useState(subscription.history?.ano_3);
  const [safra_3, setSafra3] = useState(subscription.history?.safra_3);
  const [cultura_3, setCultura3] = useState(subscription.history?.cultura_3);
  const [ano_4, setAno4] = useState(subscription.history?.ano_4);
  const [safra_4, setSafra4] = useState(subscription.history?.safra_4);
  const [cultura_4, setCultura4] = useState(subscription.history?.cultura_4);
  const [ano_5, setAno5] = useState(subscription.history?.ano_5);
  const [safra_5, setSafra5] = useState(subscription.history?.safra_5);
  const [cultura_5, setCultura5] = useState(subscription.history?.cultura_5);
  const [ano_6, setAno6] = useState(subscription.history?.ano_6);
  const [safra_6, setSafra6] = useState(subscription.history?.safra_6);
  const [cultura_6, setCultura6] = useState(subscription.history?.cultura_6);

  useEffect(() => {
    const year = new Date().getFullYear();
    setAno1(
      new Date().getMonth() >= 6
        ? `${year - 1}/${year - 2000}`
        : `${year - 2}/${year - 2001}`
    );
    setAno2(
      new Date().getMonth() >= 6
        ? `${year - 2}/${year - 2001}`
        : `${year - 2}/${year - 2001}`
    );
    setAno3(
      new Date().getMonth() >= 6
        ? `${year - 2}/${year - 2001}`
        : `${year - 3}/${year - 2002}`
    );
    setAno4(
      new Date().getMonth() >= 6
        ? `${year - 3}/${year - 2002}`
        : `${year - 3}/${year - 2002}`
    );
    setAno5(
      new Date().getMonth() >= 6
        ? `${year - 3}/${year - 2002}`
        : `${year - 4}/${year - 2003}`
    );
    setAno6(
      new Date().getMonth() >= 6
        ? `${year - 4}/${year - 2003}`
        : `${year - 4}/${year - 2003}`
    );

    setSafra1(new Date().getMonth() >= 6 ? "Verão" : "Inverno");
    setSafra2(new Date().getMonth() >= 6 ? "Inverno" : "Verão");
    setSafra3(new Date().getMonth() >= 6 ? "Verão" : "Inverno");
    setSafra4(new Date().getMonth() >= 6 ? "Inverno" : "Verão");
    setSafra5(new Date().getMonth() >= 6 ? "Verão" : "Inverno");
    setSafra6(new Date().getMonth() >= 6 ? "Inverno" : "Verão");
  }, []);

  const subscription_id = localStorage.getItem("@GetapAPP:subscription_id");

  useEffect(() => {
    if (
      subscription_id !== null &&
      Number(subscription_id) !== subscription.id
    ) {
      loadSubscription(Number(subscription_id));
    }

    setMesmoTalhao(subscription.history?.mesmo_talhao);
    setMesmoTalhaoTemp(subscription.history?.mesmo_talhao === 1 ? '1 - Sim' : '0 - Não');
    setCategory(subscription.history?.category);
    setNomeTalha(subscription.history?.nome_talha);
    setHibrido(subscription.history?.hibrido);
    setExpecProdutividade(subscription.history?.expec_produtividade);
    setDensidadeMedia(subscription.history?.densidade_media);
    setUsaFadigital(subscription.history?.usa_fadigital);
    setFerramentaFadigital(subscription.history?.ferramenta_fadigital);
    setNivelFadigital(subscription.history?.nivel_fadigital);
    setSatisfacaoFadigital(subscription.history?.satisfacao_fadigital);
    setRepetirFadigital(subscription.history?.repetir_fadigital);
    setCultura1(subscription.history?.cultura_1);
    setCultura2(subscription.history?.cultura_2);
    setCultura3(subscription.history?.cultura_3);
    setCultura4(subscription.history?.cultura_4);
    setCultura5(subscription.history?.cultura_5);
    setCultura6(subscription.history?.cultura_6);

    setAltitude(subscription.history?.altitude);
    setCustoProducao(subscription.history?.custo_producao);
    setLatitude(subscription.history?.latitude);
    setLongitude(subscription.history?.longitude);
  }, [subscription, loadSubscription, subscription_id]);

  const handleSubmit = useCallback(async () => {
    if (
      nome_talha === undefined ||
      category === undefined ||
      hibrido === undefined ||
      expec_produtividade === undefined
    ) {
      return toast.error("Preencha os campos obrigatórios!");
    }  
    api
      .post("/subscription/history", {
        subscription_id: subscription.id,
        step: 8,
        category,
        nome_talha,
        hibrido,
        expec_produtividade,
        densidade_media,
        usa_fadigital,
        ferramenta_fadigital,
        nivel_fadigital,
        satisfacao_fadigital,
        repetir_fadigital,
        ano_1,
        safra_1,
        cultura_1,
        ano_2,
        safra_2,
        cultura_2,
        ano_3,
        safra_3,
        cultura_3,
        ano_4,
        safra_4,
        cultura_4,
        ano_5,
        safra_5,
        cultura_5,
        ano_6,
        safra_6,
        cultura_6,
        custo_producao,
        altitude,
        latitude,
        longitude
      })
      .then((res) => {
        if (res.status === 201) {
          setSubscription(res.data);
          setStep(8);
          toast.success("Historico salvo com sucesso!");
          navigate("/passo9");
        }
      })
      .catch((e) => {
        toast.error(`${e.response.data.message}`);
      });
  }, [
    subscription,
    category,
    nome_talha,
    hibrido,
    expec_produtividade,
    densidade_media,
    usa_fadigital,
    ferramenta_fadigital,
    nivel_fadigital,
    satisfacao_fadigital,
    repetir_fadigital,
    ano_1,
    safra_1,
    cultura_1,
    ano_2,
    safra_2,
    cultura_2,
    ano_3,
    safra_3,
    cultura_3,
    ano_4,
    safra_4,
    cultura_4,
    ano_5,
    safra_5,
    cultura_5,
    ano_6,
    safra_6,
    cultura_6,
    custo_producao,
    altitude,
    latitude,
    longitude
  ]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title="Informações da área inscrita" />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>
        <C.ContentRightForm>
          <C.InfoSection>Histórico da área</C.InfoSection>
          <C.BoxFields>
            <C.ContentRightFormBoxLeft>
            <C.WrapperCheckbox>
              {/* <input
                type="checkbox"
                checked={mesmo_talhao === 1}
                onChange={(e) => setMesmoTalhao(e.target.checked ? 1 : 0)}
              />
              <span>
                É o mesmo talhão do concurso anterior?
              </span> */}
              <SelectBox
                    value={mesmo_talhao_temp}
                    onValueChange={(e) => setMesmoTalhao(e === '1 - Sim' ? 1 : 0)}
                    selectedItem={mesmo_talhao === 1 ? '1 - Sim' : '0 - Não'}
                    items={YesNot}
                    stylingMode="underlined"
                    labelMode="static"
                    label="É o mesmo talhão do concurso anterior?"
                    width="400"
                    height={50}
                  />
            </C.WrapperCheckbox>

              <C.BoxInput>
                <TextBox
                  value={nome_talha}
                  onValueChange={setNomeTalha}
                  stylingMode="underlined"
                  labelMode="static"
                  label="Nome do talhão inscrito (*)"
                  height={50}
                />
                <br />
              </C.BoxInput>
              <C.GroupInputsCatHibrid>
                <C.BoxInput>
                  <SelectBox
                    value={category}
                    items={CategorieSubscribe}
                    onValueChange={setCategory}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Categoria inscrita (*)"
                    placeholder="Selecione"
                    width="100%"
                    height={50}
                  />
                  <br />
                </C.BoxInput>
                <C.BoxInput>           
                  <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido}
                     width="100%"
                    height={50}
                    label="Hibrido inscrito (*)"
                    onValueChange={(e) => setHibrido(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
                  <br />
                </C.BoxInput>
              </C.GroupInputsCatHibrid>
              <C.GroupInputsCatHibrid>                
                <C.BoxInputWithBtnPlus>
                  <NumberBox
                    value={
                      expec_produtividade === undefined
                        ? undefined
                        : expec_produtividade
                    }
                    onValueChange={setExpecProdutividade}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Qual a expectativa de produtividade da área inscrita em sc/ha? (*)"
                    width="100%"
                    height={50}
                  />
                </C.BoxInputWithBtnPlus>             
                <C.BoxInput>
                  <NumericTextBox
                    // value={
                    //   custo_producao === undefined ? undefined : Number(custo_producao)
                    // }
                    inputValue={custo_producao}
                    onValueChange={setCustoProducao}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Em média, qual o custo de produção do talhão?"
                    width="100%"
                    height={50}
                    maxLength={6}
                  />
                  <br />
                </C.BoxInput>
              </C.GroupInputsCatHibrid>
              <C.GroupInputsCatHibrid>    
               <C.BoxInput>
                  <NumericTextBox
                    // value={
                    //   latitude === undefined ? undefined : Number(latitude)
                    // }
                    inputValue={latitude}
                    onValueChange={setLatitude}
                    stylingMode="underlined"
                    labelMode="static"
                    placeholder="-19.263559006"
                    label="Qual é a latitude do talhão?"
                    width="100%"
                    height={50}
                  />
                  <br />
                </C.BoxInput>
                <C.BoxInput>
                  <NumericTextBox
                    // value={
                    //   longitude === undefined ? undefined : Number(longitude)
                    // }
                    inputValue={longitude}
                    // setInputValue={setLongitude}
                    placeholder="-19.263559006"
                    onValueChange={setLongitude}
                    //  
                    label="Qual é a longitude do talhão?"
                    height={50}
                    stylingMode="underlined"
                  />
                  <br />
                </C.BoxInput>
                <C.BoxInput>
                  <NumericTextBox
                    // value={
                    //   altitude === undefined ? undefined : Number(altitude)
                    // }
                    inputValue={altitude}
                    onValueChange={setAltitude}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Qual é a altitude do talhão?"
                    width="100%"
                    height={50}
                  />
                  <br />
                </C.BoxInput>
              </C.GroupInputsCatHibrid>    
            </C.ContentRightFormBoxLeft>
          </C.BoxFields>

          <C.BoxButtonSubmit>
            <Button
              onClick={() => navigate("/passo7")}
              title="VOLTAR"
              color="#2F80ED"
              iconLeft={<BiChevronLeft fontSize={22} />}
            />
            <Button
              onClick={handleSubmit}
              title="SALVAR E AVANÇAR"
              color="#27AE60"
              iconRight={<BiChevronRight fontSize={22} />}
            />
          </C.BoxButtonSubmit>
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
