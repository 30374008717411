import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { CreateUser } from "../pages/CreateUser";
import { FormStep2 } from "../pages/FormStep2/FormStep2";
import { FormStep3 } from "../pages/FormStep3/FormStep3";
import { FormStep4 } from "../pages/FormStep4/FormStep4";
import { FormStep5 } from "../pages/FormStep5/FormStep5";
import { FormStep6 } from "../pages/FormStep6/FormStep6";
import { FormStep7 } from "../pages/FormStep7/FormStep7";
import { FormStep8 } from "../pages/FormStep8/FormStep8";
import { FormStep9 } from "../pages/FormStep9/FormStep9";
import { FormStep10 } from "../pages/FormStep10/FormStep10";
import { FormStep11 } from "../pages/FormStep11/FormStep11";
import { Login } from "../pages/Login/Login";
import { FormStep12 } from "../pages/FormStep12/FormStep12";
import { FormStep13 } from "../pages/FormStep13/FormStep13";
import { NotFound } from "../pages/NotFound";
import { useAuth } from "../hooks/auth";
import { Theme } from "../components/Theme/Theme";
import { FormProvider } from "../contexts/FormContext";
import { ForgotPass } from "../pages/ForgotPass";
import { SeeData } from "../pages/SeeData";
import { UpdatePass } from "../pages/UpdatePass";
import { Initial } from "../pages/Initial/Initial";
import { FormStep9b } from "../pages/FormStep9b/FormStep9b";

export const RoutesApp: React.FC = () => {
  const { user } = useAuth();

  function Auth({ children }: { children: JSX.Element }) {
    let location = useLocation();

    if (!user) {
      return <Navigate to="/" state={{ from: location }} replace />;
    }
    return children;
  }

  return (
    <FormProvider>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/recupera-senha" element={<ForgotPass />} />
        <Route path="/altera-senha" element={<UpdatePass />} />
        <Route
          path="/cadastro"
          element={
            <Theme>
              <CreateUser />
            </Theme>
          }
        />
        <Route
          path="/initial"
          element={
            <Auth>
              <Theme>
                <Initial />
              </Theme>
            </Auth>
          }
        />
        <Route
          path="/passo2"
          element={
            <Auth>
              <Theme>
                <FormStep2 />
              </Theme>
            </Auth>
          }
        />
        <Route
          path="/passo3"
          element={
            <Auth>
              <Theme>
                <FormStep3 />
              </Theme>
            </Auth>
          }
        />
        <Route
          path="/passo4"
          element={
            <Auth>
              <Theme>
                <FormStep4 />
              </Theme>
            </Auth>
          }
        />
          <Route
          path="/passo5"
          element={
            <Auth>
              <Theme>
                <FormStep5 />
              </Theme>
            </Auth>
          }
        />
        <Route
          path="/passo6"
          element={
            <Auth>
              <Theme>
                <FormStep6 />
              </Theme>
            </Auth>
          }
        />
        <Route
          path="/passo7"
          element={
            <Auth>
              <Theme>
                <FormStep7 />
              </Theme>
            </Auth>
          }
        />
        <Route
          path="/passo8"
          element={
            <Auth>
              <Theme>
                <FormStep8 />
              </Theme>
            </Auth>
          }
        />
        <Route
          path="/passo9"
          element={
            <Auth>
              <Theme>
                <FormStep9 />
              </Theme>
            </Auth>
          }
        />
          <Route
          path="/passo9b"
          element={
            <Auth>
              <Theme>
                <FormStep9b />
              </Theme>
            </Auth>
          }
        />
        <Route
          path="/passo10"
          element={
            <Auth>
              <Theme>
                <FormStep10 />
              </Theme>
            </Auth>
          }
        />
        <Route
          path="/passo11"
          element={
            <Auth>
              <Theme>
                <FormStep11 />
              </Theme>
            </Auth>
          }
        />
        <Route
          path="/passo12"
          element={
            <Auth>
              <Theme>
                <FormStep12 />
              </Theme>
            </Auth>
          }
        />
        <Route
          path="/passo13"
          element={
            <Auth>
              <Theme>
                <FormStep13 />
              </Theme>
            </Auth>
          }
        />
        <Route
          path="/concluido"
          element={
            <Auth>
              <Theme>
                <FormStep12 />
              </Theme>
            </Auth>
          }
        />
        <Route
          path="/dados-inscricao"
          element={
            <Auth>
              <Theme>
                <SeeData />
              </Theme>
            </Auth>
          }
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </FormProvider>
  );
};
