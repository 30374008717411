/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import * as C from "./styles";
import { Title } from "../../components/Title/Title";
import { Button } from "../../components/Button/Button";
import { BiChevronLeft, BiChevronRight, BiPlusCircle } from "react-icons/bi";
import { AiFillQuestionCircle } from "react-icons/ai";
import { useCallback, useEffect, useState } from "react";
import { NumberBox, TextBox } from "devextreme-react";
import { InfoHibrido } from "../../data/data";
import { Tooltip } from "../../components/Tooltip";
import api from "../../services/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { useAppStore } from "../../store";
import AutoCompleteCustom, { IHibrido } from "../../components/AutoCompleteCustom";
import hibridoData from "../../data/hibridos.json"

export interface ICulture {
  nome: string;
  area: string;
  sistema: string;
  id?: number;
}

export const FormStep7 = () => {
  const subscription = useAppStore((store) => store.subscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const setSubscription = useAppStore((store) => store.setSubscription);
  const setStep = useAppStore((store) => store.setStep);

  const [soja, setSoja] = useState(!!subscription.property_info?.soja);
  const [milho, setMilho] = useState(!!subscription.property_info?.milho);
  const [algodao, setAlgodao] = useState(!!subscription.property_info?.algodao);
  const [trigo, setTrigo] = useState(!!subscription.property_info?.trigo);
  const [sorgo, setSorgo] = useState(!!subscription.property_info?.sorgo);
  const [feijao, setFeijao] = useState(!!subscription.property_info?.feijao);
  const [cafe, setCafe] = useState(!!subscription.property_info?.cafe);
  const [outros, setOutros] = useState(!!subscription.property_info?.outros);
  const [desc_outros, setDescOutros] = useState(
    subscription.property_info?.desc_outros
  );
  const [area_total_milho, setAreaTotalMilho] = useState(
    subscription.property_info?.area_total_milho
  );
  const [qtd_hibridos, setQtdHibridos] = useState(
    subscription.property_info?.qtd_hibridos
  );
  const [hibrido_1, setHibrido1] = useState(
    subscription.property_info?.hibrido_1
  );
  const [hibrido_2, setHibrido2] = useState(
    subscription.property_info?.hibrido_2
  );
  const [hibrido_3, setHibrido3] = useState(
    subscription.property_info?.hibrido_3
  );
  const [hibrido_4, setHibrido4] = useState(
    subscription.property_info?.hibrido_4
  );
  const [hibrido_5, setHibrido5] = useState(
    subscription.property_info?.hibrido_5
  );
  const [hibrido_6, setHibrido6] = useState(
    subscription.property_info?.hibrido_6
  );

  const [hibrido_7, setHibrido7] = useState(
    subscription.property_info?.hibrido_7
  );
  const [hibrido_8, setHibrido8] = useState(
    subscription.property_info?.hibrido_8
  );
  const [hibrido_9, setHibrido9] = useState(
    subscription.property_info?.hibrido_9
  );


  const [hibrido_10, setHibrido10] = useState(
    subscription.property_info?.hibrido_10
  );
  const [hibrido_11, setHibrido11] = useState(
    subscription.property_info?.hibrido_11
  );
  const [hibrido_12, setHibrido12] = useState(
    subscription.property_info?.hibrido_12
  );
  const [hibrido_13, setHibrido13] = useState(
    subscription.property_info?.hibrido_13
  );
  const [hibrido_14, setHibrido14] = useState(
    subscription.property_info?.hibrido_14
  );
  const [hibrido_15, setHibrido15] = useState(
    subscription.property_info?.hibrido_15
  );

  const navigate = useNavigate();

  const subscription_id = localStorage.getItem("@GetapAPP:subscription_id");

  useEffect(() => {
    if (
      subscription_id !== null &&
      Number(subscription_id) !== subscription.id
    ) {
      loadSubscription(Number(subscription_id));
    }
    setSoja(!!subscription.property_info?.soja);
    setMilho(!!subscription.property_info?.milho);
    setAlgodao(!!subscription.property_info?.algodao);
    setTrigo(!!subscription.property_info?.trigo);
    setSorgo(!!subscription.property_info?.sorgo);
    setFeijao(!!subscription.property_info?.feijao);
    setCafe(!!subscription.property_info?.cafe);
    setOutros(!!subscription.property_info?.outros);
    setDescOutros(subscription.property_info?.desc_outros);
    setAreaTotalMilho(subscription.property_info?.area_total_milho);
    setQtdHibridos(subscription.property_info?.qtd_hibridos);
    setHibrido1(subscription.property_info?.hibrido_1);
    setHibrido2(subscription.property_info?.hibrido_2);
    setHibrido3(subscription.property_info?.hibrido_3);
    setHibrido4(subscription.property_info?.hibrido_4);
    setHibrido5(subscription.property_info?.hibrido_5);
    setHibrido6(subscription.property_info?.hibrido_6);
    setHibrido7(subscription.property_info?.hibrido_7);
    setHibrido8(subscription.property_info?.hibrido_8);
    setHibrido9(subscription.property_info?.hibrido_9);
    setHibrido10(subscription.property_info?.hibrido_10);
    setHibrido11(subscription.property_info?.hibrido_11);
    setHibrido12(subscription.property_info?.hibrido_12);
    setHibrido13(subscription.property_info?.hibrido_13);
    setHibrido14(subscription.property_info?.hibrido_14);
    setHibrido15(subscription.property_info?.hibrido_15);
  }, [subscription, loadSubscription, subscription_id]);

  const getHibridoValue = (index: number) => {
    switch (index) {
      case 1:
        return hibrido_1;
      case 2:
        return hibrido_2;
      case 3:
        return hibrido_3;
      case 4:
        return hibrido_4;
      case 5:
        return hibrido_5;
      case 6:
        return hibrido_6;
      case 7:
        return hibrido_7;
      case 8:
        return hibrido_8;
      case 9:
        return hibrido_9;
      case 10:
        return hibrido_10;
      case 11:
        return hibrido_11;
      case 12:
        return hibrido_12;
      case 13:
        return hibrido_13;
      case 14:
        return hibrido_14;
      case 15:
        return hibrido_15;
      default:
        return "";
    }
  };

// Função para verificar se as variáveis até o valor passado por parâmetro foram alteradas
const verificarHibridos = () => {
  if (qtd_hibridos === undefined || qtd_hibridos === 0) {
    return true;
  }
  console.log(`qtd_hibridos = ${qtd_hibridos}`);
  for (let x = 1; x <= qtd_hibridos; x++) {
    console.log(`getHibridoValue(${x}) = ${getHibridoValue(x)}`);
    if (getHibridoValue(x) === undefined || 
    getHibridoValue(x) === null ||
    getHibridoValue(x) === "") {
      return false;
    }
  }
  return true;
};

  const handleSubmit = useCallback(async () => {
    console.log(
      area_total_milho,
      qtd_hibridos,
      hibrido_1,
      hibrido_2,
      hibrido_3,
      hibrido_4,
      hibrido_5,
      hibrido_6,
      hibrido_7,
      hibrido_8,
      hibrido_9,
      hibrido_10,
      hibrido_11,
      hibrido_12,
      hibrido_13,
      hibrido_14,
      hibrido_15
    );

    if (
      outros &&
      (desc_outros === "" || desc_outros === undefined || desc_outros === null)
    ) {
      return toast.error("Preencha todos os campos obrigatórios!");
    }

    if (
      area_total_milho === undefined ||
      area_total_milho === null ||
      area_total_milho === "" ||
      qtd_hibridos === undefined ||
      qtd_hibridos === null
    ) {
      return toast.error("Preencha todos os campos obrigatórios!");
    }

    if (!verificarHibridos()) {
      return toast.error(`É preciso informar ${qtd_hibridos} híbridos!`);
    }

    api
      .post("/subscription/propertyinfo", {
        subscription_id: subscription.id,
        step: 7,
        soja: soja ? 1 : 0,
        milho: milho ? 1 : 0,
        algodao: algodao ? 1 : 0,
        trigo: trigo ? 1 : 0,
        sorgo: sorgo ? 1 : 0,
        feijao: feijao ? 1 : 0,
        cafe: cafe ? 1 : 0,
        outros: outros ? 1 : 0,
        desc_outros,
        area_total_milho,
        qtd_hibridos,
        hibrido_1,
        hibrido_2,
        hibrido_3,
        hibrido_4,
        hibrido_5,
        hibrido_6,
        hibrido_7,
        hibrido_8,
        hibrido_9,
        hibrido_10,
        hibrido_11,
        hibrido_12,
        hibrido_13,
        hibrido_14,
        hibrido_15
      })
      .then((res) => {
        if (res.status === 201) {
          setSubscription(res.data);
          setStep(7);
          toast.success("Informações salvas com sucesso!");
          navigate("/passo8");
        }
      });
  }, [
    subscription,
    soja,
    milho,
    algodao,
    trigo,
    sorgo,
    feijao,
    cafe,
    outros,
    desc_outros,
    area_total_milho,
    qtd_hibridos,
    hibrido_1,
    hibrido_2,
    hibrido_3,
    hibrido_4,
    hibrido_5,
    hibrido_6,
    hibrido_7,
    hibrido_8,
    hibrido_9,
    hibrido_10,
    hibrido_11,
    hibrido_12,
    hibrido_13,
    hibrido_14,
    hibrido_15,
    verificarHibridos,
    setSubscription,
    navigate,
    setStep,
  ]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title="Informações da Propriedade" />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>

        <C.ContentRightForm>
          <C.BoxFields>
            {/* <C.LabelInfo>
              Quais foram as culturas exploradas no ano passado? (*){" "}
            </C.LabelInfo> */}
            <C.ContentRightFormBoxLeft>
              {/* <Checkbox value={soja} title="Soja" func={() => setSoja(!soja)} />
              <Checkbox
                value={milho}
                title="Milho"
                func={() => setMilho(!milho)}
              /> */}
              {/* <Checkbox value={milhoTwo} title='Milho 2ª safra' func={setMilhoTwoState} /> */}
              {/* <Checkbox
                value={algodao}
                title="Algodão"
                func={() => setAlgodao(!algodao)}
              />
              <Checkbox
                value={trigo}
                title="Trigo"
                func={() => setTrigo(!trigo)}
              />
              <Checkbox
                value={sorgo}
                title="Sorgo"
                func={() => setSorgo(!sorgo)}
              />
              <Checkbox
                value={feijao}
                title="Feijão"
                func={() => setFeijao(!feijao)}
              />
              <Checkbox value={cafe} title="Café" func={() => setCafe(!cafe)} />
              <Checkbox
                value={outros}
                title="Outros"
                func={() => setOutros(!outros)}
              /> */}
            </C.ContentRightFormBoxLeft>

            {outros && (
              <C.GroupInputs>
                <C.BoxInput>
                  <TextBox
                    value={desc_outros}
                    onValueChange={(e) => setDescOutros(e)}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Digite a Cultura (*)"
                    width="100%"
                    height={50}
                    style={{ marginRight: "20px" }}
                  />
                </C.BoxInput>
              </C.GroupInputs>
            )}

            <C.BoxInput>
              <NumberBox
                value={
                  area_total_milho !== undefined
                    ? Number(area_total_milho)
                    : undefined
                }
                onValueChange={(e) => setAreaTotalMilho(`${e}`)}
                stylingMode="underlined"
                labelMode="static"
                label="Área total de milho semeada na safra atual (*)"
                height={50}
              />
              <br />
            </C.BoxInput>

            <C.LabelInfo>
              Quais híbridos de milho está sendo cultivado na safra atual? (*){" "}
              <Tooltip info={InfoHibrido} icon={<AiFillQuestionCircle />} />{" "}
            </C.LabelInfo>

            <C.BoxInputWithBtnPlus>
              <NumberBox
                value={qtd_hibridos !== undefined ? qtd_hibridos : undefined}
                onValueChange={setQtdHibridos}
                stylingMode="underlined"
                labelMode="static"
                label="Quantidade de hibridos usados "
                width="50%"
                height={50}
              />
              <span>
                <BiPlusCircle
                  fontSize={28}
                  style={{ marginRight: 10 }}
                  onClick={() => {}}
                />
              </span>
            </C.BoxInputWithBtnPlus>

            {Number(qtd_hibridos) >= 1 && (
              <C.BoxInput>
                {/* <TextBox
                  value={hibrido_1}
                  onValueChange={setHibrido1}
                  stylingMode="underlined"
                  labelMode="static"
                  label="Nome do hibrido 1"
                  width="100%"
                  height={50}
                /> */}


                  <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido_1}
                    height={50}
                    label="Nome do hibrido 1"
                    onValueChange={(e) => setHibrido1(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
              </C.BoxInput>

              
            )}
            {Number(qtd_hibridos) >= 2 && (
              <C.BoxInput>
                <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido_2}
                    height={50}
                    label="Nome do hibrido 2"
                    onValueChange={(e) => setHibrido2(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
              </C.BoxInput>
            )}
            {Number(qtd_hibridos) >= 3 && (
              <C.BoxInput>
                <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido_3}
                    height={50}
                    label="Nome do hibrido 3"
                    onValueChange={(e) => setHibrido3(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
              </C.BoxInput>
            )}
            {Number(qtd_hibridos) >= 4 && (
              <C.BoxInput>
                <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido_4}
                    height={50}
                    label="Nome do hibrido 4"
                    onValueChange={(e) => setHibrido4(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
              </C.BoxInput>
            )}
            {Number(qtd_hibridos) >= 5 && (
              <C.BoxInput>
                <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido_5}
                    height={50}
                    label="Nome do hibrido 5"
                    onValueChange={(e) => setHibrido5(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
              </C.BoxInput>
            )}
            {Number(qtd_hibridos) >= 6 && (
              <C.BoxInput>
                <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido_6}
                    height={50}
                    label="Nome do hibrido 6"
                    onValueChange={(e) => setHibrido6(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
              </C.BoxInput>
            )}

            {Number(qtd_hibridos) >= 7 && (
              <C.BoxInput>
                <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido_7}
                    height={50}
                    label="Nome do hibrido 7"
                    onValueChange={(e) => setHibrido7(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
              </C.BoxInput>
            )}


            {Number(qtd_hibridos) >= 8 && (
              <C.BoxInput>
                <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido_8}
                    height={50}
                    label="Nome do hibrido 8"
                    onValueChange={(e) => setHibrido8(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
              </C.BoxInput>
            )}

            {Number(qtd_hibridos) >= 9 && (
              <C.BoxInput>
                <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido_9}
                    height={50}
                    label="Nome do hibrido 9"
                    onValueChange={(e) => setHibrido9(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
              </C.BoxInput>
            )}

            {Number(qtd_hibridos) >= 10 && (
              <C.BoxInput>
                <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido_10}
                    height={50}
                    label="Nome do hibrido 10"
                    onValueChange={(e) => setHibrido10(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
              </C.BoxInput>
            )}

            {Number(qtd_hibridos) >= 11 && (
              <C.BoxInput>
                <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido_11}
                    height={50}
                    label="Nome do hibrido 11"
                    onValueChange={(e) => setHibrido11(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
              </C.BoxInput>
            )}


            {Number(qtd_hibridos) >= 12 && (
              <C.BoxInput>
                <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido_12}
                    height={50}
                    label="Nome do hibrido 12"
                    onValueChange={(e) => setHibrido12(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
              </C.BoxInput>
            )}

            {Number(qtd_hibridos) >= 13 && (
              <C.BoxInput>
                <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido_13}
                    height={50}
                    label="Nome do hibrido 13"
                    onValueChange={(e) => setHibrido13(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
              </C.BoxInput>
            )}

            {Number(qtd_hibridos) >= 14 && (
              <C.BoxInput>
                <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido_14}
                    height={50}
                    label="Nome do hibrido 14"
                    onValueChange={(e) => setHibrido14(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
              </C.BoxInput>
            )}


          {Number(qtd_hibridos) >= 15 && (
              <C.BoxInput>
                <AutoCompleteCustom<IHibrido>
                    itemsAvailable={hibridoData}
                    valueExpr="Cultivar"
                    searchExpr="Cultivar"
                    inputValue={hibrido_15}
                    height={50}
                    label="Nome do hibrido 15"
                    onValueChange={(e) => setHibrido15(e)}
                    labelMode="static"
                    stylingMode="underlined"
                    typeData="hibrido"
                    searchMode="startswith"  
                  />
              </C.BoxInput>
            )}
          </C.BoxFields>

          <C.BoxButtonSubmit>
            <Button
              onClick={() => navigate("/passo6")}
              title="VOLTAR"
              color="#2F80ED"
              iconLeft={<BiChevronLeft fontSize={22} />}
            />
            <Button
              onClick={handleSubmit}
              title="AVANÇAR"
              color="#27AE60"
              iconRight={<BiChevronRight fontSize={22} />}
            />
          </C.BoxButtonSubmit>
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
