import * as C from "./styles";
import { Title } from "../../components/Title/Title";
import { Button } from "../../components/Button/Button";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import "devextreme/dist/css/dx.light.css";
import { NumberBox, SelectBox, TextBox } from "devextreme-react";
import { useCallback, useEffect, useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "../../components/Tooltip";
import {
  HelpCalagem,
  HelpDataCalagem,
  HelpDataGessagem,
  HelpGessagem,
  SystemProduction,
  UnidadeMedida,
} from "../../data/data";
import { AiFillQuestionCircle } from "react-icons/ai";
import { useAppStore } from "../../store";

export const FormStep9 = () => {
  const subscription = useAppStore((store) => store.subscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const setSubscription = useAppStore((store) => store.setSubscription);
  const setStep = useAppStore((store) => store.setStep);

  const navigate = useNavigate();
  const [calagem_frequency, setCalagemFrequency] = useState(
    subscription.area_info?.calagem_frequency
  );
  const [calagem_tipo_calcario, setCalagemTipoCalcario] = useState(
    subscription.area_info?.calagem_tipo_calcario
  );
  const [calagem_dose, setCalagemDose] = useState(
    subscription.area_info?.calagem_dose
  );
  const [calagem_dose_un_medida, setCalagemDoseUnMedida] = useState(
    subscription.area_info?.calagem_dose_un_medida
  );
  const [gessagem_periodo, setGessagemPeriodo] = useState(
    subscription.area_info?.gessagem_periodo
  );
  const [gessagem_frequency, setGessagemFrequency] = useState(
    subscription.area_info?.gessagem_frequency
  );
  const [gessagem_dose, setGessagemDose] = useState(
    subscription.area_info?.gessagem_dose
  );
  const [gessagem_dose_un_medida, setGessagemDoseUnMedida] = useState(
    subscription.area_info?.gessagem_dose_un_medida
  );
  const [descompactacao_frequency, setDescompactacaoFrequency] = useState(
    subscription.area_info?.descompactacao_frequency
  );
  const [descompactacao_equipament, setDescompactacaoEquipament] = useState(
    subscription.area_info?.descompactacao_equipament
  );

  const [fornecedor, setFornecedor] = useState(
    subscription.culture_info?.fornecedor
  );
  const [sistema_producao, setSistemaProducao] = useState(
    subscription.culture_info?.sistema_producao
  );
  const [populacao_almejada, setPopulacaoAlmejada] = useState(
    subscription.culture_info?.populacao_almejada
  );
  const [espaco_linha, setEspacoLinha] = useState(
    subscription.culture_info?.espaco_linha
  );
  const [numero_semente, setNumberSemente] = useState(
    subscription.culture_info?.numero_semente
  );
  const [realiza_refugio, setRealizaRefugio] = useState(
    subscription.culture_info?.realiza_refugio
  );
  const [hibrido_refugio, setHibriRefugio] = useState(
    subscription.culture_info?.hibrido_refugio
  );
  const [tratamento_semente, setTratSement] = useState(
    subscription.culture_info?.tratamento_semente
  );
  const [nome_pacte_trat_sement, setPctTratSemente] = useState(
    subscription.culture_info?.nome_pacte_trat_sement
  );
  const [nome_pacte_trat_inocul, setPctTratInocul] = useState(
    subscription.culture_info?.nome_pacte_trat_inocul
  );
  const [nome_pacte_trat_bioestim, setPctTratBioestim] = useState(
    subscription.culture_info?.nome_pacte_trat_bioestim
  );

  const [ano_semeadora, setAnoSemeadora] = useState(
    subscription.culture_info?.ano_semeadora
  );
  const [modelo_semeadora, setMarcaSemeadora] = useState(
    subscription.culture_info?.modelo_semeadora
  );
  const [modelo_pulverizador, setMarcaPulverizador] = useState(
    subscription.culture_info?.modelo_pulverizador
  );
  const [ano_pulverizador, setAnoPulverizador] = useState(
    subscription.culture_info?.ano_pulverizador
  );

  const handleSubmit = useCallback(async () => {
    console.log(
      calagem_frequency === undefined,
      gessagem_frequency === undefined,
      descompactacao_frequency === undefined,
      sistema_producao === undefined,
      modelo_semeadora === undefined,
      modelo_pulverizador === undefined,
      ano_pulverizador === undefined,
      ano_semeadora === undefined
    );
    if (
      calagem_frequency === undefined ||
      gessagem_frequency === undefined ||
      sistema_producao === undefined ||
      // modelo_semeadora === undefined ||
      // modelo_pulverizador === undefined ||
      // ano_pulverizador === undefined ||
      // ano_semeadora === undefined ||
      gessagem_dose === undefined ||
      calagem_dose  === undefined 
      // (tratamento_semente !== undefined &&
      //   !tratamento_semente.includes("3") &&
      //   (nome_pacte_trat_bioestim === undefined ||
      //     nome_pacte_trat_inocul === undefined ||
      //     nome_pacte_trat_sement === undefined ||
      //     nome_pacte_trat_bioestim === "" ||
      //     nome_pacte_trat_inocul === "" ||
      //     nome_pacte_trat_sement === "" ||
      //     nome_pacte_trat_bioestim === null ||
      //     nome_pacte_trat_inocul === null ||
      //     nome_pacte_trat_sement === null))
    ) {
      return toast.error("Preencha os campos obrigatórios!");
    }

    api
      .post("/subscription/area", {
        subscription_id: subscription.id,
        step: 9,
        calagem_frequency,
        calagem_tipo_calcario,
        calagem_dose,
        gessagem_periodo,
        gessagem_frequency,
        gessagem_dose,
        descompactacao_frequency,
        descompactacao_equipament,
        fornecedor,
        sistema_producao,
        populacao_almejada,
        espaco_linha,
        numero_semente,
        realiza_refugio,
        hibrido_refugio,
        tratamento_semente,
        nome_pacte_trat_sement,
        nome_pacte_trat_inocul,
        nome_pacte_trat_bioestim,
        ano_semeadora,
        modelo_semeadora,
        modelo_pulverizador,
        ano_pulverizador,
      })
      .then((res) => {
        if (res.status === 201) {
          setSubscription(res.data);
          setStep(9);
          toast.success("Dados salvos com sucesso!");
          navigate("/passo9b");
        }
      })
      .catch(() => toast.error("Ops! Ocorreu um erro, tente novamente"));
  }, [
    navigate,
    setStep,
    subscription,
    calagem_frequency,
    calagem_tipo_calcario,
    calagem_dose,
    gessagem_periodo,
    gessagem_frequency,
    gessagem_dose,
    descompactacao_frequency,
    descompactacao_equipament,
    fornecedor,
    populacao_almejada,
    espaco_linha,
    numero_semente,
    realiza_refugio,
    hibrido_refugio,
    tratamento_semente,
    nome_pacte_trat_sement,
    nome_pacte_trat_inocul,
    nome_pacte_trat_bioestim,
    ano_semeadora,
    modelo_semeadora,
    modelo_pulverizador,
    ano_pulverizador,
    setSubscription,
    sistema_producao,
  ]);

  const subscription_id = localStorage.getItem("@GetapAPP:subscription_id");

  useEffect(() => {
    if (
      subscription_id !== null &&
      Number(subscription_id) !== subscription.id
    ) {
      loadSubscription(Number(subscription_id));
    }
    setCalagemFrequency(subscription.area_info?.calagem_frequency);
    setCalagemTipoCalcario(subscription.area_info?.calagem_tipo_calcario);
    setCalagemDose(subscription.area_info?.calagem_dose);
    setCalagemDoseUnMedida(subscription.area_info?.calagem_dose_un_medida || "Kg/ha");
    setGessagemPeriodo(subscription.area_info?.gessagem_periodo);
    setGessagemFrequency(subscription.area_info?.gessagem_frequency);
    setGessagemDose(subscription.area_info?.gessagem_dose);
    setGessagemDoseUnMedida(subscription.area_info?.gessagem_dose_un_medida || "Kg/ha");
    setDescompactacaoFrequency(
      subscription.area_info?.descompactacao_frequency
    );
    setDescompactacaoEquipament(
      subscription.area_info?.descompactacao_equipament
    );
    setFornecedor(subscription.culture_info?.fornecedor);
    setSistemaProducao(subscription.culture_info?.sistema_producao);
    setPopulacaoAlmejada(subscription.culture_info?.populacao_almejada);
    setEspacoLinha(subscription.culture_info?.espaco_linha);
    setNumberSemente(subscription.culture_info?.numero_semente);
    setRealizaRefugio(subscription.culture_info?.realiza_refugio);
    setHibriRefugio(subscription.culture_info?.hibrido_refugio);
    setTratSement(subscription.culture_info?.tratamento_semente);
    setPctTratSemente(subscription.culture_info?.nome_pacte_trat_sement);
    setPctTratInocul(subscription.culture_info?.nome_pacte_trat_inocul);
    setPctTratBioestim(subscription.culture_info?.nome_pacte_trat_bioestim);
    setAnoSemeadora(subscription.culture_info?.ano_semeadora);
    setMarcaSemeadora(subscription.culture_info?.modelo_semeadora);
    setMarcaPulverizador(subscription.culture_info?.modelo_pulverizador);
    setAnoPulverizador(subscription.culture_info?.ano_pulverizador);
  }, [loadSubscription, subscription, subscription_id]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title="Informações da área inscrita: Solo" />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>

        <C.ContentRightForm>
          <C.BoxFields>
            <C.ContentRightFormBoxLeft>
              <C.InfoSection>
                Calagem de solo{" "}
                <Tooltip
                  info={HelpDataCalagem}
                  icon={<AiFillQuestionCircle />}
                />{" "}
              </C.InfoSection>

              <C.GroupInputs>
                <C.BoxInput>
                  <div
                    style={{
                      position: "absolute",
                      right: "30px",
                      zIndex: "1000",
                      color: "#2F80ED",
                    }}
                  >
                    <Tooltip
                      info={HelpCalagem}
                      icon={<AiFillQuestionCircle />}
                    />
                  </div>
                  <NumberBox
                    value={
                      calagem_frequency !== undefined
                        ? calagem_frequency
                        : undefined
                    }
                    onValueChange={setCalagemFrequency}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Frequência (a cada x anos) (*)"
                    width="100%"
                    height={50}
                  />
                  <br />
                </C.BoxInput>              
                <C.BoxInput>
                  <NumberBox
                    value={calagem_dose}
                    onValueChange={setCalagemDose}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Dose (*)"
                    width={150}
                    height={50}
                  />
                   </C.BoxInput>
                  <C.BoxInput>
                  <SelectBox
                        value={calagem_dose_un_medida}
                        onValueChange={setCalagemDoseUnMedida}
                        items={UnidadeMedida}
                        stylingMode="underlined"
                        labelMode="static"
                        label="Unidade de medida"
                        width="100%"
                        height={50}
                      />
                  <br />             
                
                </C.BoxInput>
              </C.GroupInputs>

              <C.InfoSection>
                Gessagem{" "}
                <Tooltip
                  info={HelpDataGessagem}
                  icon={<AiFillQuestionCircle />}
                />{" "}
              </C.InfoSection>

              <C.GroupInputs>
                <C.BoxInput>
                  <div
                    style={{
                      position: "absolute",
                      right: "30px",
                      zIndex: "1000",
                      color: "#2F80ED",
                    }}
                  >
                    <Tooltip
                      info={HelpGessagem}
                      icon={<AiFillQuestionCircle />}
                    />
                  </div>
                  <NumberBox
                    value={
                      gessagem_frequency !== undefined
                        ? gessagem_frequency
                        : undefined
                    }
                    onValueChange={setGessagemFrequency}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Frequência (a cada x anos) (*)"
                    width="100%"
                    height={50}
                  />
                  <br />
                </C.BoxInput>
                <C.BoxInput>
                  <NumberBox
                    value={gessagem_dose}
                    onValueChange={setGessagemDose}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Dose (*)"
                    width={150}
                    height={50}
                  />
                </C.BoxInput>
                <C.BoxInput>
                   <SelectBox
                        value={gessagem_dose_un_medida}
                        onValueChange={setGessagemDoseUnMedida}
                        items={UnidadeMedida}
                        stylingMode="underlined"
                        labelMode="static"
                        label="Unidade de medida"
                        width={150}
                        height={50}
                      />
                  <br />
                </C.BoxInput>
              </C.GroupInputs>             
            </C.ContentRightFormBoxLeft>
          </C.BoxFields>

          <C.HeaderContentRight>
            <Title title="Informações da área inscrita: Implantação da cultura" />
            <span>(*) Campos obrigatórios</span>
          </C.HeaderContentRight>
          <br />
          <br />
          <C.BoxFields>
            <C.ContentRightFormBoxLeft>
              <C.GroupInputs>
                <C.BoxInput>
                  <TextBox
                    value={subscription?.history?.hibrido}
                    readOnly
                    stylingMode="underlined"
                    labelMode="static"
                    label="Hibrido usado na área inscrita (*)"
                    width="100%"
                    height={50}
                  />
                </C.BoxInput>
                <SelectBox
                  value={sistema_producao}
                  onValueChange={(e) => setSistemaProducao(e)}
                  items={SystemProduction}
                  stylingMode="underlined"
                  labelMode="static"
                  label="Sistema de produção (*)"
                  placeholder="Selecione"
                  width="100%"
                  height={50}
                />
              </C.GroupInputs>

              <C.GroupInputsEpecified>
                <C.BoxInput>
                  <NumberBox
                    value={populacao_almejada}
                    onValueChange={setPopulacaoAlmejada}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Populacao almejada"
                    width="100%"
                    height={50}
                    max={999999}
                  />
                </C.BoxInput>            
              </C.GroupInputsEpecified>
            </C.ContentRightFormBoxLeft>
          </C.BoxFields>
          <C.BoxButtonSubmit>
            <Button
              onClick={() => navigate("/passo8")}
              title="VOLTAR"
              color="#2F80ED"
              iconLeft={<BiChevronLeft fontSize={22} />}
            />
            <Button
              onClick={handleSubmit}
              title="SALVAR E AVANÇAR"
              color="#27AE60"
              iconRight={<BiChevronRight fontSize={22} />}
            />
          </C.BoxButtonSubmit>
          <br />
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
