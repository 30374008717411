import * as C from "./styles";
import { Title } from "../../components/Title/Title";
import { Button } from "../../components/Button/Button";
import { BiChevronLeft, BiChevronRight } from "react-icons/bi";

import "devextreme/dist/css/dx.light.css";
import { NumberBox, SelectBox, TextBox } from "devextreme-react";
import { UF } from "../../data/data";
import { useNavigate } from "react-router-dom";
import { useCallback, useEffect, useMemo, useState } from "react";
import { toast } from "react-toastify";
import api from "../../services/api";
import { useAppStore } from "../../store";
import Autocomplete from "devextreme-react/autocomplete";
import { Tooltip } from "../../components/Tooltip";
import { AiFillQuestionCircle } from "react-icons/ai";
import municipiosData from  "../../data/municipios.json";

export type Municipios = {
  ID_UF: number, UF: string, MESO: string,  ID_MICRO: string, MICRO: string, ID_MUN: string, MUN: string
}

export const FormStep4 = () => {
  const subscription = useAppStore((store) => store.subscription);
  const navigate = useNavigate();
  const [fazenda, setFazenda] = useState(subscription.property?.fazenda);
  const [endereco, setEndereco] = useState(subscription.property?.endereco);
  const [cidade, setCidade] = useState(subscription.property?.cidade);
  const [uf, setUf] = useState(subscription.property?.estado);
  const [altFazenda, setAltFazenda] = useState(subscription.property?.altitude);
  const [tempAtividade, setTempAtividade] = useState(
    subscription.property?.tempo_atividade
  );
  const [totalImovel, setTotalImovel] = useState(
    subscription.property?.area_total
  );
  const [areaAgricultavel, setAreaAgricultavel] = useState(subscription.property?.area_agricultavel)
  const [areaPropria, setAreaPropria] = useState(
    subscription.property?.area_propria
  );
  let setAreaArrendada = subscription.property?.area_arrendada;
  const [sucessor, setSucessor] = useState(subscription.property?.sucessor);
  const [herdeiro, setHerdeiro] = useState(subscription.property?.herdeiro);
  const [ccir, setCcir] = useState(subscription.property?.ccir || '')

  const setSubscription = useAppStore((store) => store.setSubscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const isLoading = useAppStore((store) => store.isLoading);
  const setStep = useAppStore((store) => store.setStep);
  const [municipiosPopup, setMunicipiosPopup] = useState(municipiosData)

  const handleValueChangeCidade = (e: any) => {
    console.log(`e.value = ${e.value}`);
    const item = municipiosPopup.find(h => h.MUN.toLowerCase().startsWith(e.value.toLowerCase()));
    console.log(`item = ${item}`);
    // const item = hibridosData.find(i => i.Cultivar === e.value);
    setCidade(e.value);
    if (!item) {
      toast.warning(`Por favor informe um valor válido para a cidade.`);
    }
  };

  const handleOnFocusOutCidade = (e: any) => {
    if (cidade === undefined || cidade === "") {
      return;
    }
    const item = municipiosPopup.find(i => i.MUN === cidade);
    if (!item) {
      setCidade(''); // Limpa o campo ou mantenha o último valor válido
      toast.warning(`Por favor informe um valor válido para a Cidade.`);
    }
  };

  const updateDatasource = (e : string) => {
    setMunicipiosPopup(municipiosData.filter(i => i["UF"] === e));
  }

  const calArea = useMemo(() => {
    if (areaPropria !== "") {
      // eslint-disable-next-line react-hooks/exhaustive-deps
      setAreaArrendada = `${100 - Number(areaPropria)}`;
      return 100 - Number(areaPropria);
    } else {
      return 0;
    }
  }, [areaPropria, setAreaArrendada]);

  const handleSaveStep3 = useCallback(async () => {
    console.log(`areaAgricultavel = ${areaAgricultavel}; ccir = ${ccir}`);
    const _areaAgricultavel = areaAgricultavel || 0;
    const _totalImovel = totalImovel || 0;
    console.log(`_totalImovel = ${_totalImovel}`)
    console.log(`_areaAgricultavel = ${_areaAgricultavel}`)
    console.log(`_areaAgricultavel <= _totalImovel = ${_areaAgricultavel <= _totalImovel}`)

    const areaTotal : boolean = _areaAgricultavel <= _totalImovel;
    if (!areaTotal ) {
      return toast.error("Preencha um valor válido para a área agricultável!");
    }

    if (
      fazenda === "" ||
      endereco === "" ||
      cidade === "" ||
      uf === "" ||
      totalImovel === undefined ||
      areaAgricultavel === "" ||
      ccir === ""
    ) {
      return toast.error("Preencha todos os campos obrigatórios!");
    }

    if (ccir.length < 2) {
      return toast.error("Preencha o campo CAR Nacional corretamente!");
    }

    if (!(/[a-zA-Z]/).test(ccir.charAt(0)) || !(/[a-zA-Z]/).test(ccir.charAt(1))) {
      return toast.error("Os 2 primeiros caracteres do campo CAR Nacional precisam ser 2 letras!");
    }

    try {
      api
        .post("/subscription/property", {
          subscription_id: subscription.id,
          step: 4,
          fazenda: fazenda,
          endereco: endereco,
          cidade: cidade,
          estado: uf,
          altitude: altFazenda,
          tempo_atividade: tempAtividade,
          area_total: totalImovel,
          area_agricultavel: areaAgricultavel,
          area_propria: areaPropria === undefined ? 100 : areaPropria,
          area_arrendada: setAreaArrendada === undefined ? 0 : setAreaArrendada,
          sucessor: sucessor,
          herdeiro: herdeiro,
          ccir: ccir,
        })
        .then((res) => {
          if (res.status === 201) {
            setSubscription(res.data);
            if (subscription.step < 4) {
              setStep(4);
            }
            toast.success("Salvo com sucesso");
            navigate("/passo5");
          }
        });
    } catch (error) {}
  }, [
    fazenda,
    endereco,
    uf,
    altFazenda,
    tempAtividade,
    totalImovel,
    areaAgricultavel,
    areaPropria,
    sucessor,
    herdeiro,
    cidade,
    ccir,
    navigate,
    subscription,
    setSubscription,
    setStep,
    setAreaArrendada
    ]);

  const subscription_id = localStorage.getItem("@GetapAPP:subscription_id");

  useEffect(() => {
    if (
      subscription_id !== null &&
      Number(subscription_id) !== subscription.id
    ) {
      loadSubscription(Number(subscription_id));
    }

    setFazenda(subscription.property?.fazenda);
    setEndereco(subscription.property?.endereco);
    setCidade(subscription.property?.cidade);
    setUf(subscription.property?.estado);
    setAltFazenda(subscription.property?.altitude);
    setTempAtividade(subscription.property?.tempo_atividade);
    setTotalImovel(subscription.property?.area_total);
    setAreaAgricultavel(subscription.property?.area_agricultavel);
    setAreaPropria(subscription.property?.area_propria);
    setSucessor(subscription.property?.sucessor);
    setHerdeiro(subscription.property?.herdeiro);
    setCcir(subscription.property?.ccir || '');
  }, [subscription_id, subscription, loadSubscription]);

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title="Dados pessoais e de propriedade" />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>
        {isLoading && (
          <C.ContentRightForm>
            <div
              style={{
                flex: 1,
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Title title="Carregando..." />
            </div>
          </C.ContentRightForm>
        )}

        {!isLoading && (
          <C.ContentRightForm>
            <C.BoxFields>
              <C.ContentRightFormBoxLeft>
                <C.BoxInput>
                  <TextBox
                    value={fazenda}
                    onValueChange={(e) => setFazenda(e)}
                    stylingMode="underlined"
                    labelMode="static"
                    label="Fazenda inscrita (*)"
                    height={50}
                  />
                  <br />
                </C.BoxInput>              
                <C.GroupInputs>
                <C.BoxInput>
                    <SelectBox
                      value={uf}
                      items={UF}
                      onValueChange={(e) => {
                        setUf(e);
                        updateDatasource(e);
                        setCidade("");
                      }}
                      stylingMode="underlined"
                      labelMode="static"
                      label="UF (*)"
                      width="100%"
                      height={50}
                    />
                  </C.BoxInput>
                  <C.BoxInput>
                    <Autocomplete
                        dataSource={municipiosPopup}
                        valueExpr="MUN"
                        searchExpr="MUN"
                        value={cidade}
                        label="Cidade da fazenda inscrita (*)"
                        labelMode="static"
                        stylingMode="underlined"
                        onValueChanged={handleValueChangeCidade}
                        onFocusOut={handleOnFocusOutCidade}
                        height={50}
                      />
                  </C.BoxInput>
                  
                  <C.BoxInput>
                    <NumberBox
                      value={altFazenda ? Number(altFazenda) : undefined}
                      onValueChange={(e) => setAltFazenda(e)}
                      stylingMode="underlined"
                      labelMode="static"
                      label="Altitude da fazenda(m)"
                      width="100%"
                      height={50}
                      defaultValue={0}
                    />
                  </C.BoxInput>
                </C.GroupInputs>
                <br />

                <C.GroupInputs>
                 
                  <C.BoxInput>
                    <NumberBox
                      value={totalImovel ? Number(totalImovel) : undefined}
                      onValueChange={(e) => setTotalImovel(e)}
                      stylingMode="underlined"
                      labelMode="static"
                      label="Área total do imóvel(ha) (*)"
                      width="100%"
                      height={50}
                      defaultValue={0}
                    />
                  </C.BoxInput>
                  <C.BoxInput>
                    <NumberBox
                      value={areaAgricultavel ? Number(areaAgricultavel) : undefined}
                      onValueChange={(e) => setAreaAgricultavel(e)}
                      stylingMode="underlined"
                      labelMode="static"
                      label="Área Agricultável do imóvel(ha) (*)"
                      width="100%"
                      height={50}
                      defaultValue={0}
                    />
                  </C.BoxInput>
                  <C.BoxInput>
                    <NumberBox
                      value={areaPropria ? Number(areaPropria) : undefined}
                      min={1}
                      max={100}
                      onValueChange={(e) => setAreaPropria(e)}
                      stylingMode="underlined"
                      labelMode="static"
                      label="Área própria (% total)"
                      width="100%"
                      height={50}
                      defaultValue={100}
                    />
                  </C.BoxInput>
                </C.GroupInputs>
                <br />

                <C.GroupInputs>
                  <C.BoxInput>
                    <NumberBox
                      readOnly
                      value={calArea}
                      min={1}
                      max={100}
                      stylingMode="underlined"
                      labelMode="static"
                      label="Área arrendada (% total)"
                      width="100%"
                      height={50}
                    />
                  </C.BoxInput>                
                  </C.GroupInputs>
                  <br />
                  <C.GroupInputs>
                  <C.BoxInput>
                  <TextBox
                      value={ccir}
                      onValueChange={(e) => setCcir(e)}
                      stylingMode="underlined"
                      labelMode="static"
                     label="CAR Nacional (*)"
                     placeholder="MT-5106752-94CD.2763.A9FF.4CA0.8CCA.584D.BC91.E585"
                      mask="AA-0000000-0000.0000.0000.0000.0000.0000.0000.0000"
                      width="100%"
                      maxLength={50}
                      height={50}
                    />
                    <Tooltip info={'MT-5106752-94CD.2763.A9FF.4CA0.8CCA.584D.BC91.E585'} icon={<AiFillQuestionCircle />} />{" "}                       
                  </C.BoxInput>
                </C.GroupInputs>
                <br />
              </C.ContentRightFormBoxLeft>
            </C.BoxFields>

            <C.BoxButtonSubmit>
              <Button
                onClick={() => navigate("/passo3")}
                title="VOLTAR"
                color="#2F80ED"
                iconLeft={<BiChevronLeft fontSize={22} />}
              />
              <Button
                onClick={handleSaveStep3}
                title="SALVAR E AVANÇAR"
                color="#27AE60"
                iconRight={<BiChevronRight fontSize={22} />}
              />
            </C.BoxButtonSubmit>
          </C.ContentRightForm>
        )}
      </C.ContentRight>
    </C.Container>
  );
};
