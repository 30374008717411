import * as C from "./styles";
import { Title } from "../../components/Title/Title";
import { Button } from "../../components/Button/Button";
import { BiChevronLeft, BiChevronRight, BiPlusCircle } from "react-icons/bi";

import "devextreme/dist/css/dx.light.css";
import { Autocomplete, DataGrid, NumberBox, SelectBox, TextBox } from "devextreme-react";
import {
  DataEstadioFenologico,
  HelpFungicida,
  HelpHerbicida,
  HelpInseticidaCigarrinha,
  HelpInseticidaLagarta,
  HelpNematicida,
  UnidadeMedidaFormulaAdubo,
} from "../../data/data";
import { useCallback, useEffect, useMemo, useState } from "react";
import api from "../../services/api";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "../../components/Tooltip";
import { AiFillQuestionCircle } from "react-icons/ai";
import { Column } from "devextreme-react/data-grid";
import { FaEdit, FaTrash } from "react-icons/fa";
import { Modal } from "../../components/Modal";
import { useAppStore } from "../../store";
import { IListItem } from "../../store/types";
import defensivosData from "../../data/defensivos.json"
import { Item } from "devextreme-react/autocomplete";
import axios from "axios";

export const FormStep11 = () => {
  const subscription = useAppStore((store) => store.subscription);
  const loadSubscription = useAppStore((store) => store.loadSubscription);
  const loadListNutriCultive = useAppStore(
    (store) => store.loadListNutriCultive
  );
  const listNutriCultive = useAppStore((store) => store.listNutriCultive);
  const setSubscription = useAppStore((store) => store.setSubscription);
  const setStep = useAppStore((store) => store.setStep);

  const navigate = useNavigate();

  const [nome, setNome] = useState("");

  const [isPopupVisible, setPopupVisibility] = useState(false);
  const [isPopupEditVisible, setPopupEditVisibility] = useState(false);
  const [isModalVisibel, setIsModalVisible] = useState(false);
  const [isModalEditVisible, setIsModalEditVisible] = useState(false);
  const [isModalDeleteVisible, setIsModalDeleteVisible] = useState(false);
  const [idToDelete, setIdToDelete] = useState("");

  const [idToUpdate, setIdToUpdate] = useState("");
  const [formula, setFormula] = useState("");
  const [dose, setDose] = useState("");
  const [dose_un_medida, setDoseUnMedida] = useState("")
  const [nomeComercial, setNomeComercial] = useState("");
  const [fabricante, setFabricante] = useState("");
  const [estadioFenologico, setEstadioFenologico] = useState("");
  const [outros, setOutros] = useState("");

  const [qtd_herbi, setQtdHerbi] = useState(subscription.cultivo?.qtd_herbi);
  const [qtd_lagarta, setQtdLagarta] = useState(
    subscription.cultivo?.qtd_lagarta
  );
  const [qtd_cigarra, setQtdCigarra] = useState(
    subscription.cultivo?.qtd_cigarra
  );
  const [qtd_fungicida, setQtdFungicida] = useState(
    subscription.cultivo?.qtd_fungicida
  );
  const [qtd_biodefenciva, setQtdNematicida] = useState(
    subscription.cultivo?.qtd_biodefenciva
  );

  const subscription_id = localStorage.getItem("@GetapAPP:subscription_id");
  const [dataSource, setDataSource] = useState<Item[]>([]);

  useEffect(() => {
    // Carregar dados do arquivo JSON local
    axios.get<Item[]>('/defensivos.json')
      .then(response => {
        setDataSource(response.data);
      })
      .catch(error => {
        console.error("Erro ao carregar dados do JSON", error);
      });
  }, []);

  const handleValueChangeNomeComercial = (e: any) => {
    console.log(`e.value = ${e.value}`);
    const item = defensivosData.find(h => h["marca_comercial"].toLowerCase().startsWith(e.value.toLowerCase()));
    console.log(`item = ${item}`);
    setNomeComercial(e.value);
    if (!item) {
      toast.warning(`Por favor informe um valor válido para o nome comercial.`);
    }

    let element = defensivosData.find(i => i["marca_comercial"] === e.value);
    console.log(element);
    setFormula(element?.Ingrediente!);
    setFabricante(element?.Fabricante!);
  };

  const handleOnFocusOutNomeComercial = (e: any) => {
    if (nomeComercial === undefined || nomeComercial === "") {
      return;
    }
    const item = defensivosData.find(i => i["marca_comercial"] === nomeComercial);
    if (!item) {
      setNomeComercial(''); // Limpa o campo ou mantenha o último valor válido
      toast.warning(`Por favor informe um valor válido para o nome comercial.`);
    }
  };

  useEffect(() => {
    if (
      subscription_id !== null &&
      Number(subscription_id) !== subscription.id
    ) {
      loadSubscription(Number(subscription_id));
      loadListNutriCultive(Number(subscription_id));
    }

    setQtdHerbi(subscription.cultivo?.qtd_herbi);
    setQtdLagarta(subscription.cultivo?.qtd_lagarta);
    setQtdCigarra(subscription.cultivo?.qtd_cigarra);
    setQtdFungicida(subscription.cultivo?.qtd_fungicida);
    setQtdNematicida(subscription.cultivo?.qtd_biodefenciva);
  }, [loadSubscription, subscription, subscription_id, loadListNutriCultive]);

  const togglePopup = useCallback(
    (title: string, name: string) => {
      setIsModalVisible(true);
      setPopupVisibility(!isPopupVisible);
      // setTitulo(title);
      setNome(name);
    },
    [isPopupVisible, setPopupVisibility]
  );

  const togglePopupEdit = useCallback(
    (data: any) => {
      setIsModalEditVisible(true);
      setPopupEditVisibility(!isPopupEditVisible);

      setNome(data.data.nome);

      setIdToUpdate(data.data.id);
      setFormula(data.data.formula);
      setDose(data.data.dose);
      setDoseUnMedida(data.data.dose_un_medida  || "Kg/ha")
      setNomeComercial(data.data.nome_comercial);
      setFabricante(data.data.fabricante);
      setEstadioFenologico(data.data.estadio_veget_feno);
      setOutros(data.data.outros);
    },
    [isPopupEditVisible]
  );

  const loadData = useCallback(async () => {
    await loadListNutriCultive(subscription.id);
  }, [subscription, loadListNutriCultive]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const nutri = useMemo(() => {
    const HERBICIDA = listNutriCultive.reduce(
      (acc: IListItem[], item: IListItem) => {
        return item.nome === "HERBICIDA" ? (acc = [...acc, item]) : acc;
      },
      [] as IListItem[]
    );

    const LAGARTAS = listNutriCultive.reduce(
      (acc: IListItem[], item: IListItem) => {
        return item.nome === "LAGARTA" ? (acc = [...acc, item]) : acc;
      },
      [] as IListItem[]
    );

    const CIGARRAS = listNutriCultive.reduce(
      (acc: IListItem[], item: IListItem) => {
        return item.nome === "CIGARRA" ? (acc = [...acc, item]) : acc;
      },
      [] as IListItem[]
    );

    const FUNGICIDA = listNutriCultive.reduce(
      (acc: IListItem[], item: IListItem) => {
        return item.nome === "FUNGICIDA" ? (acc = [...acc, item]) : acc;
      },
      [] as IListItem[]
    );

    const BIODEFENSIVO = listNutriCultive.reduce(
      (acc: IListItem[], item: IListItem) => {
        return item.nome === "BIODEFENSIVO" ? (acc = [...acc, item]) : acc;
      },
      [] as IListItem[]
    );

    return {
      HERBICIDA,
      LAGARTAS,
      CIGARRAS,
      FUNGICIDA,
      BIODEFENSIVO,
    };
  }, [listNutriCultive]);

  const cleanData = useCallback(() => {
    setPopupVisibility(!isPopupVisible);
    setNome("");
    setIdToUpdate("");
    setNome("");
    setFormula("");
    setDose("");
    setDoseUnMedida("");
    setNomeComercial("");
    setFabricante("");
    setEstadioFenologico("");
    setOutros("");
  }, [isPopupVisible]);

  const handleSubmitInfo = useCallback(async () => {
    if (
      formula === "" ||
      dose === "" ||
      nomeComercial === "" ||
      estadioFenologico === ""
    ) {
      return toast.error("Preencha todos os campos obrigratorios!");
    }
    if (idToUpdate !== "") {
      api
        .put("/subscription/list", {
          list_id: idToUpdate,
          nome,
          formula,
          dose,
          nome_comercial: nomeComercial,
          fabricante,
          estadio_veget_feno: estadioFenologico,
          nome_outros: outros,
        })
        .then((res) => {
          if (res.status === 200) {
            loadData();

            setPopupEditVisibility(false);
            setPopupVisibility(false);
            setIsModalVisible(false);
            setIsModalEditVisible(false);
            toast.success("Registro salvo com sucesso!");
          }
        })
        .catch((e) => toast.error(`${e.response.data.message}`))
        .finally(() => {
          cleanData();
        });
    } else {
      api
        .post("/subscription/list_cultive", {
          subscription_id: subscription.id,
          nome,
          formula,
          dose,
          nome_comercial: nomeComercial,
          fabricante,
          estadio_veget_feno: estadioFenologico,
          nome_outros: outros,
        })
        .then((res) => {
          if (res.status === 201) {
            loadData();
            setPopupEditVisibility(false);
            setPopupVisibility(false);
            setIsModalVisible(false);
            toast.success("Registro salvo com sucesso!");
          }
        })
        .catch((e) => toast.error(`${e.response.data.message}`))
        .finally(() => {
          cleanData();
        });
    }
  }, [
    subscription,
    setPopupVisibility,
    loadData,
    nome,
    formula,
    dose,
    nomeComercial,
    fabricante,
    idToUpdate,
    estadioFenologico,
    outros,
    cleanData,
  ]);

  const [screen, setScreen] = useState(0);

  const handleSubmitNextStep = useCallback(() => {
    if (subscription.id === 0) {
      return toast.error("Ainda não foi criado um manejo!");
    }

    if (
      qtd_herbi === undefined ||
      qtd_lagarta === undefined ||
      qtd_cigarra === undefined ||
      qtd_fungicida === undefined ||
      qtd_biodefenciva === undefined
    ) {
      return toast.error("Número de aplicações é obrigatório!");
    }

    if (qtd_herbi > 0 && nutri.HERBICIDA.length === 0) {
      return toast.error("Adicione pelo menos uma aplicação Herbicida!");
    }
    if (qtd_lagarta > 0 && nutri.LAGARTAS.length === 0) {
      return toast.error(
        "Adicione pelo menos uma aplicação Inseticida(Lagartas)!"
      );
    }
    if (qtd_cigarra > 0 && nutri.CIGARRAS.length === 0) {
      return toast.error(
        "Adicione pelo menos uma aplicação Inseticida(Cigarrinhas)!"
      );
    }
    if (qtd_fungicida > 0 && nutri.FUNGICIDA.length === 0) {
      return toast.error("Adicione pelo menos uma aplicação Fungicida!");
    }

    console.log(qtd_biodefenciva);
    if (
      qtd_biodefenciva > 0 &&
      nutri.BIODEFENSIVO.length !== qtd_biodefenciva
    ) {
      return toast.error(
        `Adicione ${
          qtd_biodefenciva > 1 ? "todas as apliacações" : "a aplicação"
        } de Biodefensivo que declarou!`
      );
    }

    api
      .post(`/subscription/cultive`, {
        subscription_id: subscription.id,
        step: 11,
        qtd_cigarra,
        qtd_fungicida,
        qtd_herbi,
        qtd_lagarta,
        qtd_biodefenciva,
      })
      .then((res) => {
        setSubscription(res.data);
        setStep(11);
        toast.success("Dados salvos com sucesso!");
        navigate("/passo12");
      })
      .catch((err) => {
        return toast.error(err.response.data.message);
      });
  }, [
    navigate,
    setStep,
    subscription,
    setSubscription,
    qtd_cigarra,
    qtd_fungicida,
    qtd_herbi,
    qtd_lagarta,
    qtd_biodefenciva,
    nutri,
  ]);

  useEffect(() => {
    setScreen(window.screen.width);
  }, [screen]);

  const openModalDelete = (id: string) => {
    setIdToDelete(id);
    setIsModalDeleteVisible(true);
  };

  const handleDeleteRegister = () => {
    api
      .delete(`/subscription/list/${idToDelete}`)
      .then((res) => {
        loadData();
        toast.success("Registro excluído com sucesso");
        setIsModalDeleteVisible(false);
      })
      .catch((e) => toast.error(`${e.response.data.message}`))
      .finally(() => {
        setPopupVisibility(!isPopupVisible);
        setNome("");
      });
  };

  const handleButtonsActions = (data: any) => {
    return (
      <C.BoxUpdateDelete>
        <FaEdit
          cursor={"pointer"}
          onClick={() => togglePopupEdit(data)}
          color={"#2F80ED"}
        />
        <FaTrash
          cursor={"pointer"}
          onClick={() => openModalDelete(data.data.id)}
          color={"#e73838"}
        />
      </C.BoxUpdateDelete>
    );
  };

  return (
    <C.Container>
      <C.ContentRight>
        <C.HeaderContentRight>
          <Title title="Informações da área inscrita: Proteção de cultivos" />
          <span>(*) Campos obrigatórios</span>
        </C.HeaderContentRight>
        <br />
        <C.ContentRightForm>
          <C.BoxFields>
            <C.ContentRightFormBoxLeft>
              <C.BoxButton>
                <C.Label>
                  Herbicida{" "}
                  <span style={{ color: "#2F80ED" }}>
                    <Tooltip
                      info={HelpHerbicida}
                      icon={<AiFillQuestionCircle />}
                    />
                  </span>
                </C.Label>
                {qtd_herbi !== undefined && qtd_herbi > 0 && (
                  <C.BtnAdd
                    onClick={() => togglePopup("Herbicida", "HERBICIDA")}
                    style={{ marginRight: "20px" }}
                  >
                    Adicionar
                    <BiPlusCircle size={22} color={"#333"} />
                  </C.BtnAdd>
                )}
                <NumberBox
                  value={qtd_herbi}
                  onValueChange={(e) => setQtdHerbi(e)}
                  stylingMode="underlined"
                  labelMode="static"
                  label="Número de aplicações (*)"
                  width="200px"
                  height={50}
                  style={{ marginLeft: "20px" }}
                />
              </C.BoxButton>

              {isModalVisibel && (
                <Modal
                  titleHeader="Preencha os campos abaixo"
                  onClose={() => setIsModalVisible(false)}
                  mxheight="650px"
                >
                  <C.GroupInputs>
                  
                  <C.BoxInput>                     
                      <Autocomplete
                        dataSource={dataSource}
                        label="Nome Comercial (*)"
                        valueExpr="marca_comercial"
                        searchExpr="marca_comercial"
                        value={nomeComercial}
                        stylingMode="underlined"
                        labelMode="static"
                        height={50}                  
                        onValueChanged={handleValueChangeNomeComercial}
                        onFocusOut={handleOnFocusOutNomeComercial}
                      />
                    </C.BoxInput>
                    
                    <C.BoxInput>
                      <TextBox
                        value={formula}
                        onValueChange={setFormula}
                        stylingMode="underlined"
                        labelMode="static"
                        label="Ingrediente Ativo (*)"
                        width="100%"
                        readOnly={true}
                        height={50}
                        disabled={true}
                        style={{ marginRight: "20px" }}
                      />                      
                  </C.BoxInput>
                    <C.BoxInput>
                      <TextBox
                        value={estadioFenologico}
                        onValueChange={setEstadioFenologico}
                        stylingMode="underlined"
                        labelMode="static"
                        label="Estádio Vegetativo (*)"
                        width="100%"
                        height={50}
                        style={{ marginRight: "20px" }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <TextBox
                        value={fabricante}
                        onValueChange={setFabricante}
                        stylingMode="underlined"
                        labelMode="static"
                        label="Fabricante"
                        readOnly={true}
                        width="100%"
                        height={50}
                        disabled={true}
                        style={{ marginRight: "20px" }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <NumberBox
                        defaultValue={dose}
                        onValueChange={setDose}
                        stylingMode="underlined"
                        labelMode="static"
                        label="Dose (*)"
                        width="100%"
                        height={50}
                        style={{ marginRight: "20px" }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                    <SelectBox
                        value={dose_un_medida}
                        onValueChange={setDoseUnMedida}
                        items={UnidadeMedidaFormulaAdubo}
                        stylingMode="underlined"
                        labelMode="static"
                        label="Unidade de medida da dose"
                        width="100%"
                        height={50}
                      />
                    </C.BoxInput>                   
                
                    
                  </C.GroupInputs>
                  <br />
                  <C.BoxButtonSubmit>
                    <Button
                      onClick={handleSubmitInfo}
                      title="SALVAR"
                      color="#27AE60"
                      style={{ marginLeft: "auto" }}
                      iconRight={<BiChevronRight fontSize={22} />}
                    />
                  </C.BoxButtonSubmit>
                </Modal>
              )}

              {isModalEditVisible && (
                <Modal
                  titleHeader="Preencha os campos abaixo"
                  onClose={() => setIsModalEditVisible(false)}
                  mxheight="650px"
                >
                  <C.GroupInputs>
                  <C.BoxInput>
                      <Autocomplete
                        dataSource={dataSource}
                        label="Nome Comercial (*)"
                        valueExpr="marca_comercial"
                        searchExpr="marca_comercial"
                        value={nomeComercial}
                        stylingMode="underlined"
                        labelMode="static"
                        height={50}
                        onValueChanged={handleValueChangeNomeComercial}
                        onFocusOut={handleOnFocusOutNomeComercial}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <TextBox
                        value={formula}
                        onValueChange={setFormula}
                        stylingMode="underlined"
                        labelMode="static"
                        label="Ingrediente Ativo (*)"
                        width="100%"
                        height={50}
                        disabled={true}
                        style={{ marginRight: "20px" }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <SelectBox
                        value={estadioFenologico}
                        onValueChange={setEstadioFenologico}
                        items={DataEstadioFenologico}
                        stylingMode="underlined"
                        labelMode="static"
                        label="Estádio Vegetativo (*)"
                        width="100%"
                        height={50}
                        style={{ marginRight: "20px" }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <TextBox
                        value={fabricante}
                        onValueChange={setFabricante}
                        stylingMode="underlined"
                        labelMode="static"
                        label="Fabricante"
                        width="100%"
                        height={50}
                        disabled={true}
                        style={{ marginRight: "20px" }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                      <NumberBox
                        defaultValue={dose}
                        onValueChange={setDose}
                        stylingMode="underlined"
                        labelMode="static"
                        label="Dose (*)"
                        width="100%"
                        height={50}
                        style={{ marginRight: "20px" }}
                      />
                    </C.BoxInput>
                    <C.BoxInput>
                    <SelectBox
                        value={dose_un_medida}
                        onValueChange={setDoseUnMedida}
                        items={UnidadeMedidaFormulaAdubo}
                        stylingMode="underlined"
                        labelMode="static"
                        label="Unidade de medida da dose"
                        width="100%"
                        height={50}
                      />
                    </C.BoxInput>  
                    
                  </C.GroupInputs>
                  <br />
                  <C.BoxButtonSubmit>
                    <Button
                      onClick={handleSubmitInfo}
                      title="ATUALIZAR"
                      color="#27AE60"
                      style={{ marginLeft: "auto" }}
                      iconRight={<BiChevronRight fontSize={22} />}
                    />
                  </C.BoxButtonSubmit>
                </Modal>
              )}

              {isModalDeleteVisible && (
                <Modal
                  titleHeader="Excluier registro"
                  onClose={() => setIsModalDeleteVisible(false)}
                  mxheight="220px"
                >
                  <h2 style={{ margin: "0 auto", color: "#444" }}>
                    Deseja excluir este registro?
                  </h2>
                  <br />

                  <C.BoxButtonSubmit>
                    <Button
                      onClick={handleDeleteRegister}
                      title="Excluir"
                      color="#27AE60"
                      style={{ marginLeft: "auto" }}
                    />
                  </C.BoxButtonSubmit>
                </Modal>
              )}

              {nutri?.HERBICIDA !== undefined &&
                nutri?.HERBICIDA.length > 0 && (
                  <DataGrid
                    dataSource={nutri?.HERBICIDA}
                    focusedRowEnabled={false}
                    hoverStateEnabled={true}
                    keyExpr={"id"}
                  >
                    <Column
                      caption={"Ingrediente Ativo"}
                      dataField={"formula"}
                    />
                    <Column
                      caption={"Estádio Vegetativo"}
                      dataField={"estadio_veget_feno"}
                    />
                    <Column caption={"Dose (kg/ha)"} dataField={"dose"} />

                    <Column
                      caption={"#"}
                      alignment={"center"}
                      cellRender={handleButtonsActions}
                    />
                  </DataGrid>
                )}

              <C.Separator />

              <C.BoxButton>
                <C.Label>
                  Inseticida (lagartas){" "}
                  <span style={{ color: "#2F80ED" }}>
                    <Tooltip
                      info={HelpInseticidaLagarta}
                      icon={<AiFillQuestionCircle />}
                    />
                  </span>
                </C.Label>
                {qtd_lagarta !== undefined && qtd_lagarta > 0 && (
                  <C.BtnAdd
                    onClick={() =>
                      togglePopup("Inseticida (lagartas)", "LAGARTA")
                    }
                    style={{ marginRight: "20px" }}
                  >
                    Adicionar
                    <BiPlusCircle size={22} color={"#333"} />
                  </C.BtnAdd>
                )}
                <NumberBox
                  value={qtd_lagarta}
                  onValueChange={(e) => setQtdLagarta(e)}
                  stylingMode="underlined"
                  labelMode="static"
                  label="Número de aplicações (*)"
                  width="200px"
                  height={50}
                  style={{ marginLeft: "20px" }}
                />
              </C.BoxButton>

              {nutri?.LAGARTAS !== undefined && nutri?.LAGARTAS.length > 0 && (
                <DataGrid
                  dataSource={nutri?.LAGARTAS}
                  focusedRowEnabled={false}
                  hoverStateEnabled={true}
                  keyExpr={"id"}
                >
                  <Column caption={"Ingrediente Ativo"} dataField={"formula"} />
                  <Column
                    caption={"Estádio Vegetativo"}
                    dataField={"estadio_veget_feno"}
                  />
                  <Column caption={"Dose (kg/ha)"} dataField={"dose"} />

                  <Column
                    caption={"#"}
                    alignment={"center"}
                    cellRender={handleButtonsActions}
                  />
                </DataGrid>
              )}

              <C.Separator />

              <C.BoxButton>
                <C.Label>
                  Inseticida (cigarrinhas){" "}
                  <span style={{ color: "#2F80ED" }}>
                    <Tooltip
                      info={HelpInseticidaCigarrinha}
                      icon={<AiFillQuestionCircle />}
                    />
                  </span>
                </C.Label>
                {qtd_cigarra !== undefined && qtd_cigarra > 0 && (
                  <C.BtnAdd
                    onClick={() =>
                      togglePopup("Inseticida (cigarrinhas)", "CIGARRA")
                    }
                    style={{ marginRight: "20px" }}
                  >
                    Adicionar
                    <BiPlusCircle size={22} color={"#333"} />
                  </C.BtnAdd>
                )}
                <NumberBox
                  value={qtd_cigarra}
                  onValueChange={(e) => setQtdCigarra(e)}
                  stylingMode="underlined"
                  labelMode="static"
                  label="Número de aplicações (*)"
                  width="200px"
                  height={50}
                  style={{ marginLeft: "20px" }}
                />
              </C.BoxButton>

              {nutri?.CIGARRAS !== undefined && nutri?.CIGARRAS.length > 0 && (
                <DataGrid
                  dataSource={nutri?.CIGARRAS}
                  focusedRowEnabled={false}
                  hoverStateEnabled={true}
                  keyExpr={"id"}
                >
                  <Column caption={"Ingrediente Ativo"} dataField={"formula"} />
                  <Column
                    caption={"Estádio Vegetativo"}
                    dataField={"estadio_veget_feno"}
                  />
                  <Column caption={"Dose (kg/ha)"} dataField={"dose"} />

                  <Column
                    caption={"#"}
                    alignment={"center"}
                    cellRender={handleButtonsActions}
                  />
                </DataGrid>
              )}

              <C.Separator />

              <C.BoxButton>
                <C.Label>
                  Fungicida{" "}
                  <span style={{ color: "#2F80ED" }}>
                    <Tooltip
                      info={HelpFungicida}
                      icon={<AiFillQuestionCircle />}
                    />
                  </span>
                </C.Label>
                {qtd_fungicida !== undefined && qtd_fungicida > 0 && (
                  <C.BtnAdd
                    onClick={() => togglePopup("Fungicida", "FUNGICIDA")}
                    style={{ marginRight: "20px" }}
                  >
                    Adicionar
                    <BiPlusCircle size={22} color={"#333"} />
                  </C.BtnAdd>
                )}
                <NumberBox
                  value={qtd_fungicida}
                  onValueChange={(e) => setQtdFungicida(e)}
                  stylingMode="underlined"
                  labelMode="static"
                  label="Número de aplicações (*)"
                  width="200px"
                  height={50}
                  style={{ marginLeft: "20px" }}
                />
              </C.BoxButton>

              {nutri?.FUNGICIDA !== undefined &&
                nutri?.FUNGICIDA.length > 0 && (
                  <DataGrid
                    dataSource={nutri?.FUNGICIDA}
                    focusedRowEnabled={false}
                    hoverStateEnabled={true}
                    keyExpr={"id"}
                  >
                    <Column
                      caption={"Ingrediente Ativo"}
                      dataField={"formula"}
                    />
                    <Column
                      caption={"Estádio Vegetativo"}
                      dataField={"estadio_veget_feno"}
                    />
                    <Column caption={"Dose (kg/ha)"} dataField={"dose"} />

                    <Column
                      caption={"#"}
                      alignment={"center"}
                      cellRender={handleButtonsActions}
                    />
                  </DataGrid>
                )}

              <C.Separator />

              <C.BoxButton>
                <C.Label>
                  Biodefensivo{" "}
                  <span style={{ color: "#2F80ED" }}>
                    <Tooltip
                      info={HelpNematicida}
                      icon={<AiFillQuestionCircle />}
                    />
                  </span>
                </C.Label>
                {qtd_biodefenciva !== undefined && qtd_biodefenciva > 0 && (
                  <C.BtnAdd
                    onClick={() => togglePopup("Biodefensivo", "BIODEFENSIVO")}
                    style={{ marginRight: "20px" }}
                  >
                    Adicionar
                    <BiPlusCircle size={22} color={"#333"} />
                  </C.BtnAdd>
                )}
                <NumberBox
                  value={qtd_biodefenciva}
                  onValueChange={(e) => setQtdNematicida(e)}
                  stylingMode="underlined"
                  labelMode="static"
                  label="Número de aplicações (*)"
                  width="200px"
                  height={50}
                  style={{ marginLeft: "20px" }}
                />
              </C.BoxButton>

              {nutri?.BIODEFENSIVO !== undefined &&
                nutri?.BIODEFENSIVO.length > 0 && (
                  <DataGrid
                    dataSource={nutri?.BIODEFENSIVO}
                    focusedRowEnabled={false}
                    hoverStateEnabled={true}
                    keyExpr={"id"}
                  >
                    <Column
                      caption={"Ingrediente Ativo"}
                      dataField={"formula"}
                    />
                    <Column
                      caption={"Estádio Vegetativo"}
                      dataField={"estadio_veget_feno"}
                    />
                    <Column caption={"Dose (kg/ha)"} dataField={"dose"} />

                    <Column
                      caption={"#"}
                      alignment={"center"}
                      cellRender={handleButtonsActions}
                    />
                  </DataGrid>
                )}
            </C.ContentRightFormBoxLeft>
          </C.BoxFields>

          <C.BoxButtonSubmit>
            <Button
              onClick={() => navigate("/passo10")}
              title="VOLTAR"
              color="#2F80ED"
              iconLeft={<BiChevronLeft fontSize={22} />}
            />
            <Button
              onClick={handleSubmitNextStep}
              title="SALVAR E AVANÇAR"
              color="#27AE60"
              iconRight={<BiChevronRight fontSize={22} />}
            />
          </C.BoxButtonSubmit>
        </C.ContentRightForm>
      </C.ContentRight>
    </C.Container>
  );
};
